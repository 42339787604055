/* Styles scoped to the login-page component */
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Fill the viewport height */
    background: linear-gradient(135deg, #4b79a1, #283e51);
    padding: 20px;
    box-sizing: border-box; /* Ensure padding doesn’t affect layout */
}

.login-card {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    padding: 30px;
    text-align: center;
    width: 100%;
    max-width: 400px; /* Restrict card width */
    color: #333;
}

.login-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #4b79a1;
}

.login-subtitle {
    font-size: 16px;
    margin-bottom: 20px;
    color: #555;
}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.login-input {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    outline: none;
    box-sizing: border-box;
    transition: border-color 0.3s;
}

.login-input:focus {
    border-color: #4b79a1;
    box-shadow: 0 0 5px rgba(75, 121, 161, 0.4);
}

.login-button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background: #4b79a1;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s;
}

.login-button:hover {
    background: #375d7f;
}

.login-error {
    margin-top: 15px;
    color: #e74c3c;
    font-size: 14px;
}
.set-password-button {
    margin-top: 10px;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    padding: 10px 20px;
}

.back-button {
    margin-top: 10px;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    padding: 10px 20px;
}