.cell-container {
  border: 1px solid #e0e0e0;
  padding: 15px;
  border-radius: 8px;
  background-color: #34495e;
  cursor: pointer;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

.cell-container:hover {
  background-color: #3e5062e7;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.cell-container.selected {
  border: 2px solid #007bff;
  /* Highlight border */
  background-color: #668bb6;
  /* Light blue background */
}

.cell-title {
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.cell-children-icon {
  float: right;
  font-size: 12px;
  color: #7f8c8d;
}