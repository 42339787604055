/* General Scene Styling */
.scene {
    position: relative;
    width: 100%;
    height: 100vh;
    background: #87CEEB; /* Sky blue */
    overflow: hidden;
  }
  
  /* Sun */
  .sun {
    position: absolute;
    top: 5%;
    left: 80%;
    width: 80px;
    height: 80px;
    animation: sun-move 10s linear infinite;
  }
  
  /* Clouds */
  .cloud {
    position: absolute;
    width: 120px;
    opacity: 0.8;
    animation: cloud-move 20s linear infinite;
  }
  
  .cloud1 {
    top: 10%;
    left: -20%; /* Start off-screen */
  }
  
  .cloud2 {
    top: 25%;
    left: 100%; /* Start off-screen */
  }
  
  /* Cloud animation */
  @keyframes cloud-move {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(120vw); /* Smoothly move off-screen */
    }
  }
  
  /* Sky Text Styling */
  .sky-text {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: white;
  }
  
  /* Title Styling */
  .sky-title {
    font-size: 20px;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    margin: 0;
  }
  
  /* Citation Styling */
  .sky-citation {
    font-size: 10px;
    margin-top: 5px;
    font-style: italic;
    color: #f0f8ff;
  }