html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f9fafb;
}

/* Top header for Home button */
.top-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  background: linear-gradient(to right, #2c3e50, #1c2833);
  /* Matches button color */
  border-bottom: 3px solid #2980b9;
  width: 100%;
  box-sizing: border-box;
  /* Rounded top corners */
}
/* Shared button styles for Home and Modal buttons */
.top-header .home-button,
.top-header .modal-button {
  padding: 5px 100px;
  font-size: 14px;
  background: #445e78;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-right: 10px; /* Space between buttons */
}

.top-header .home-button:hover,
.top-header .modal-button:hover {
  background-color: #0056b3;
  color: white;
}

/* Main content layout */
.main-content {
  display: flex;
  width: 100%;
  height: calc(100% - 60px);  /* Adjust for header height */
  gap: 20px;
  padding: 10px;
  background-color: #f9fafb;
  box-sizing: border-box;
  overflow: hidden; /* Prevent layout overflow */
}

/* Ensure the NodeEditor takes up the full viewport */
.node-editor-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

/* Hierarchy tree layout */
.hierarchy-tree {
  overflow-y: auto; /* Allow scrolling when content overflows */
  border: 1px solid #d1d9e6;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  height: 88vh; /* Match the editor wrapper height */
}

.hierarchy-tree h3 {
  font-size: 18px;
  font-weight: 600;
  color: #34495e;
  margin-bottom: 15px;
}

.tree-node {
  display: flex;
  flex-direction: column;
  min-width: fit-content;
}
.hidden-indicator {
  color: #ff5722;
  font-size: 0.9em;
  margin-left: 10px;
}

.hierarchy-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  background-color: white;
  transition: background-color 0.2s ease;
}
.hierarchy-title {
  cursor: pointer;
  padding: 10px;
  border-radius: 6px;
}
.hierarchy-title:hover {
  background-color: #e6f0ff;
  padding: 10px;
}
.hierarchy-title.selected {
  background-color: #445e78;
  color: white;
  padding: 10px;
}
.tree-children {
  margin-left: 20px;
  border-left: 1px solid #d1d9e6;
  padding-left: 10px;
}

/* Editor wrapper */
.editor-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Allow vertical scrolling within the editor if needed */
  border: 1px solid #d1d9e6;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  height: 88vh; /* Ensure the editor takes up the full height */
  max-height: 100%;
}

/* Editor panel styling */
.editor-panel {
  flex: 1;
  height: 100%;
  max-height: 100%;
  padding: 25px;
  border: 1px solid #d1d9e6;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  overflow-y: auto;
}

.editor-panel h3 {
  font-size: 20px;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 20px;
}

.editor-panel label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #34495e;
}

.editor-panel input,
.editor-panel textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #d1d9e6;
  border-radius: 6px;
  font-size: 16px;
  color: #2c3e50;
  background-color: #f9fafb;
  transition: border-color 0.3s ease;
}

.editor-panel input:focus,
.editor-panel textarea:focus {
  border-color: #007bff;
  outline: none;
  background-color: white;
}

/* Spacing adjustments for buttons */
.icon-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 16px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-right: 12px;
  margin-top: 8px;
  /* Adds vertical spacing between buttons */
}
.icon-button.toggle {
  background-color: #ffc107;
}


.icon-button.save {
  background-color: #28a745;
}

.icon-button.add {
  background-color: #007bff;
}

.icon-button.delete {
  background: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.icon-button.delete:hover {
  background: #c82333;
}

/* Children list styling */
.child-list {
  padding: 0;
  list-style: none;
  margin-top: 10px;
}
.child-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 8px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: grab;
  transition: background-color 0.2s ease;
}

.child-item:hover {
  background-color: #f0f8ff;
}

.child-item:active {
  cursor: grabbing;
}

/* Empty editor message styling */
.empty-editor {
  padding: 20px;
  color: #7f8c8d;
  font-size: 18px;
  text-align: center;
  background-color: #f9fafb;
  border-radius: 8px;
  border: 1px dashed #d1d9e6;
}

.resizer {
  background-color: #ddd;
  width: 10px;
  cursor: col-resize;
  z-index: 1;
}

/* SaveMessage.css */
.save-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #34495e;
  color: white;
  font-size: 1.5rem;
  padding: 10px;
  border-radius: 10px;
  z-index: 9999; /* Ensures it appears above other content */
}

/* Spinner Container */
.loading-box {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Ensures it appears above other content */
}

/* Rotating Icon */
.loading-icon {
  width: 80px;
  height: auto;
  animation: spin 2s linear infinite; /* Smooth rotation */
  filter: drop-shadow(0 0 10px #34495e);
}

/* Glowing Animation for Icon */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Loading Text */
.loading-text {
  font-size: 1.2rem;
  margin-top: 15px;
  color: #34495e;
  animation: fade-in-out 3s infinite; /* Subtle blinking effect */
}