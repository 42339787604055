/* header.css */
.header {
    text-align: center;
    padding: 40px 20px;
    background-color: #34495e;
    color: #ecf0f1;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .header-title {
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 1.5px;
    margin-bottom: 10px;
  }
  
  .header-subtitle {
    font-size: 18px;
    font-weight: 300;
    color: #bdc3c7;
  }