/* reset.css */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #2c3e50;
  line-height: 1.6;
  background-color: #f4f6f9;
  margin: 0;
  padding: 0;
}