/* display-view.css */
.display-view {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to right, #34495e, #2c3e50);
    color: #ecf0f1;
    padding: 20px;
    text-align: center;
    overflow: hidden;
  }
  
  /* Default Message Slide */
  .default-slide {
    max-width: 800px;
    text-align: center;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  .slide-title {
    font-size: 3em; /* Slightly reduced font size to accommodate long titles */
    font-weight: bold;
    margin-bottom: 0.5em;
    color: #ecf0f1;
    max-width: 100%; /* Increase the width to span most of the screen */
    word-wrap: break-word; /* Ensure long words wrap */
    text-align: center; /* Keep title text centered */
  }
  
  .slide-subtitle {
    font-size: 3.5em;
    font-weight: 300;
    color: #bdc3c7;
  }
  
  /* Content Slide */
  .content-slide {
    max-width: 80%;
    text-align: center;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  /* Slide Body Styling */
/* Slide Body Styling */
.slide-body {
  font-size: 1.8em; /* Slightly larger font size for readability */
  line-height: 1.2; /* Adjusted for better spacing between lines */
  text-align: left; /* Keep text left-aligned */
  color: #ecf0f1;
  margin-top: 20px;
  white-space: pre-wrap; /* Preserve line breaks */
}

/* Paragraphs: Reduce Vertical Gaps */
.slide-body p {
  margin: 0; /* Remove default margins */
  padding: 5px 0; /* Minimal vertical padding between paragraphs */
}

/* Div Elements: Controlled Spacing */
.slide-body div {
  margin: 0; /* Reset default margins */
  padding: 5px 0; /* Minimal vertical padding for divs */
}

/* Reduce Spacing Between Nested Elements */
.slide-body div > div {
  margin: 0;
  padding: 3px 0;
}

/* Optional: Adjust Heading Styles Inside the Body */
.slide-body strong {
  font-weight: bold;
  color: #f1c40f; /* Highlighted text in bold for better emphasis */
}

/* Italicized Text Styling */
.slide-body em {
  font-style: italic;
  color: #bdc3c7; /* Slightly muted for differentiation */
}

/* Link Styling for Consistency */
.slide-body a {
  color: #1abc9c; /* Teal for clickable links */
  text-decoration: underline;
}

.slide-body a:hover {
  color: #16a085; /* Slightly darker teal on hover */
}
/* List Styling for Ordered and Unordered Lists */
.slide-body ol,
.slide-body ul {
    margin: 0; /* Remove default margins */
    padding: 0 1px; /* Add controlled padding for indentation */
    list-style-position: inside; /* Ensure the list numbers/bullets align with the text */
    line-height: 0.9; /* Improve readability with proper spacing */
    color: #ecf0f1; /* Ensure consistent color */
    font-size: clamp(0.4em, 78%, 1.4em); 
}

/* List Items */
.slide-body li {
  display: list-item; /* Ensure proper list item behavior */
  list-style-position: outside; /* Keep list markers outside */
}

/* Ordered List Numbers */
.slide-body ol {
    list-style-type: decimal; /* Ensure numbered lists display correctly */
}

/* Unordered List Bullets */
.slide-body ul {
    list-style-type: disc; /* Use discs for unordered lists */
}

/* Nested Lists */
.slide-body ol li > ol,
.slide-body ul li > ul {
    margin-top: 0px; /* Add slight spacing for nested lists */
}

/* Adjust Nested List Items */
.slide-body ol li > ol li,
.slide-body ul li > ul li {
    margin-bottom: 0px; /* Reduce spacing for nested list items */
}

/* Optional: Handle Block Elements in Lists */
.slide-body ol p,
.slide-body ul p {
    margin: 0; /* Remove excessive gaps in paragraphs within list items */
    padding: 0px 0; /* Consistent spacing for paragraphs inside lists */
}
.slide-question {
  font-size: 6em; /* Slightly reduced font size to accommodate long titles */
  font-weight: bold;
  margin-bottom: 0.5em;
  color: #ecf0f1;
  max-width: 100%; /* Increase the width to span most of the screen */
  word-wrap: break-word; /* Ensure long words wrap */
  text-align: center; /* Keep title text centered */
}
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }