/* Button to open the modal */
.guideline-button {
  padding: 7px 40px;
  font-size: 16px;
  background: #445e78;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin: 10px 10px; /* Add space around the button */
}

.guideline-button:hover {
  background: #1c2833;
}
  
  /* Modal overlay styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal content styles */
  .modal-content {
    background-color: #ffffff;
    border-radius: 8px;
    width: 80%;
    max-width: 900px;
    max-height: 90%;
    padding: 20px;
    overflow-y: auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  }
  
  /* Close button */
  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: transparent;
    color: #ff0000;
    font-size: 24px;
    border: none;
    cursor: pointer;
  }
  
  .close-button:hover {
    color: #cc0000;
  }
  
  /* General content styles */
  .guideline-title {
    font-size: 24px;
    font-weight: bold;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .guideline-section h2 {
    font-size: 20px;
    color: #34495e;
    margin-bottom: 10px;
  }
  
  .guideline-section h3 {
    font-size: 18px;
    color: #2980b9;
    margin-bottom: 10px;
  }
  
  .guideline-section ul {
    padding-left: 20px;
    list-style-type: disc;
  }
  
  .guideline-section ul ul {
    list-style-type: circle;
  }
  
  .guideline-section li {
    margin-bottom: 8px;
  }
  /* Close button styles */
.close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: transparent;
    color: #ff0000;
    font-size: 24px;
    border: none;
    cursor: pointer;
  }
  
  .close-button:hover {
    color: #cc0000;
  }
  
  /* Footer with Done button */
  .modal-footer {
    margin-top: 20px;
    text-align: center;
  }
  
  .done-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .done-button:hover {
    background-color: #218838;
  }