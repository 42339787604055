/* buttons.css */
.icon-button,
.home-button {
  padding: 10px 20px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.icon-button:hover,
.home-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}