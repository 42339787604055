/* layout.css */
.page-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .main-content {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  
  .viewer-tab-button {
    margin-bottom: 20px;
    padding: 10px 20px;
    font-size: 14px;
    color: white;
    background-color: #28a745;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .viewer-tab-button:hover {
    background-color: #218838;
    transform: translateY(-2px);
  }
  
  .viewer-tab-button:active {
    background-color: #1e7e34;
    transform: translateY(0);
  }