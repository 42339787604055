.navigator-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #445e78; /* Muted grey-blue for less brightness */
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  gap: 16px;
}


.navigator-title {
  font-size: 20px;
  font-weight: 600;
  color: #34495e;
  margin-bottom: 10px;
}

.viewer-tab-button,
.back-button {
  display: inline-block;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #2c3e50;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.viewer-tab-button:hover,
.back-button:hover {
  background-color: #1c2833;
}
.child-list {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Add more spacing between cells */
}

.child-list .cell {
  background: #4b5968; /* Medium blue for cell background */
  border: 1px solid #2c3e50; /* Darker border for definition */
  padding: 18px 20px;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 500;
  color: #f9fafb; /* Light text */
  cursor: pointer;
  transition: all 0.3s ease;
}

.child-list .cell:hover {
  background: #56687a; /* Slightly brighter blue on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.child-list .cell:active {
  transform: translateY(0);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.expanded-single-view {
  padding: 20px;
  background-color: #f4f6f8; /* Same light grey for continuity */
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.expanded-title {
  font-size: 24px;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 12px;
}

.notes-box {
  padding: 16px;
  background-color: #f1f3f5;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.notes-content {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}
/* Spinner Container */
.hq-loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: first baseline;
  margin-top: 10vh;
  height: 100%; /* Ensures it fills the parent */
  text-align: center;
}

/* Rotating Icon */
.hq-loading-icon {
  width: 80px;
  height: auto;
  animation: spin 2s linear infinite; /* Smooth rotation */
  filter: drop-shadow(0 0 10px #2980b9);
}

/* Glowing Animation for Icon */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Loading Text */
.hq-loading-text {
  font-size: 1.2rem;
  margin-top: 15px;
  color: #ecf0f1;
  animation: fade-in-out 3s infinite; /* Subtle blinking effect */
}

@keyframes fade-in-out {
  0%, 100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}