/* General container for the 404 page */
.scene {
    position: relative;
    width: 100%;
    height: 100vh;
    background: #87CEEB; /* Sky blue */
    overflow: hidden;
  }
  
  /* Sun */
  .sun {
    position: absolute;
    top: 5%;
    left: 80%;
    width: 80px;
    height: 80px;
    animation: sun-move 10s linear infinite;
  }
  
  /* Clouds */
  .cloud {
    position: absolute;
    width: 120px;
    opacity: 0.8;
    animation: cloud-move 20s linear infinite;
  }
  
  .cloud1 {
    top: 10%;
    left: -20%; /* Start off-screen */
  }
  
  .cloud2 {
    top: 25%;
    left: 100%; /* Start off-screen */
  }
  
  /* Cloud animation */
  @keyframes cloud-move {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(120vw); /* Smoothly move off-screen */
    }
  }
  
  /* Text in the Sky */
.sky-text {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: white;
  }
  
  /* Title Styling */
  .sky-title {
    font-size: 48px;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    margin: 0;
  }
  
  /* Subtext Styling */
  .sky-subtext {
    font-size: 24px;
    margin: 10px 0;
    font-style: italic;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  }
  
  /* Citation Styling */
  .sky-citation {
    font-size: 20px;
    margin: 5px 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  }
  
  /* Shepherd */
  .shepherd {
    position: absolute;
    bottom: 15%;
    left: 10%;
    width: 100px;
    animation: shepherd-bounce 5s linear infinite;
    z-index: 10; /* Bring shepherd in front of hills */
  }
  
  /* Sheep */
  .sheep {
    position: absolute;
    width: 60px;
    animation: sheep-bounce 4s ease-in-out infinite;
    z-index: 10; /* Bring sheep in front of hills */
  }
  
  @keyframes sheep-bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
  }
  
  @keyframes shepherd-bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  
  /* General Hill Styling */
/* General Hill Styling */
.hill {
    position: absolute;
    width: 200%;
    height: 40vh; /* Extend height for better coverage */
    clip-path: ellipse(75% 40% at 50% 100%); /* Default rolling hill shape */
  }
  
  /* Front Hill */
  .hill1 {
    background: linear-gradient(to top, #228B22, #2E8B57); /* Dark green */
    z-index: 3; /* Front-most hill */
    bottom: 0vh; /* Base-aligned */
    clip-path: ellipse(85% 65% at 50% 100%); /* Slightly wider for smooth overlap */
  }
  
  /* Middle Hill */
  .hill2 {
    background: linear-gradient(to top, #32CD32, #3CB371); /* Medium green */
    z-index: 2; /* Middle layer */
    bottom: 5vh; /* Layering effect */
    clip-path: ellipse(90% 80% at 70% 100%); /* Wider and higher */
  }
  
  /* Back Hill */
  .hill3 {
    background: linear-gradient(to top, #66CDAA, #98FB98); /* Light green */
    z-index: 1; /* Furthest back */
    bottom: 20vh; /* Furthest down for layering */
    clip-path: ellipse(95% 45% at 30% 100%); /* Largest hill for the background */
  }
  
  .home-button {
    margin-top: 20px; /* Space between the text and button */
    padding: 12px 24px;
    font-size: 16px;
    color: white;
    background: #007bff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .home-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
    transform: scale(1.05); /* Subtle hover effect */
  }