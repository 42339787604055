/* General Layout */
.hq-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(to bottom, #1c2833, #2c3e50);
  color: #f9fafb;
  margin: 0 auto; /* Center the container */
  max-width: 100%; /* Allow full width */
}

/* Header */
.hq-header {
  height: 20vh;
  background: linear-gradient(to right, #2c3e50, #1c2833);
  text-align: center;
  padding: 20px;
  border-bottom: 3px solid #2980b9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hq-header-content {
  z-index: 2;
}

.hq-header-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #ecf0f1;
}

.hq-header-subtitle {
  font-size: 1.2rem;
  color: #bdc3c7;
  margin-top: 10px;
}

/* Main Content */
.hq-main {
  display: grid;
  grid-template-columns: 20vw 36vw 36vw; /* Adjusted for better balance */
  width: 100vw; /* Constrain the layout width */
  gap: 2vw;
  flex-grow: 1;
  padding: 2vw;
  background: #34495e;
  transition: grid-template-columns 1s ease; /* Smooth resizing */
  margin: 0 auto; /* Center the main grid */
}

.hq-main.collapsed-sidebar {
  grid-template-columns: 0vw 40vw 50vw; /* Evenly distribute space when collapsed */
}

.hq-navigator, .hq-notes {
  transition: width 1s ease;
}

/* Sidebar Collapse Button */
.hq-toggle-sidebar {
  background: #445e78;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  z-index: 10;
  transition: all 1s ease;
}

.hq-toggle-sidebar:hover {
  background: #1c2833;
}
/* Sidebar */
.hq-sidebar {
  background: #2c3e50;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, opacity 0.3s ease;
  transform: translateX(0); /* Fully visible by default */
  opacity: 1;
}

.hq-sidebar.hidden {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 1s ease, opacit 1s ease;
  height: 10%; /* Maintain consistent height when hidden */
}

.hq-sidebar-logo {
  width: 80px;
  height: auto;
  margin-bottom: 20px;
}

.hq-sidebar-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

/* Navigator Section */
.hq-navigator {
  background: #2c3e50; /* Slightly lighter shade of the dark blue */
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  color: #f9fafb; /* Light text for readability */
  overflow-y: auto;
}


.hq-section-title {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: white;
}

/* Notes Section */
.hq-notes {
  background: #2c3e50; /* Match the center card background */
  color: #f9fafb; /* Light text for readability */
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
}
.hq-notes.visible {
  opacity: 1;
  transform: translateY(0);
}

.hq-notes-header {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ecf0f1; /* Slightly brighter text for headers */
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 2px solid #56687a; /* Subtle divider */
}

.notes-box {
  padding: 20px;
  background: #4b5968; /* Slightly darker background for boxes */
  border-radius: 8px;
  color: #f9fafb;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.notes-content {
  font-size: 1rem;
  line-height: 1.6;
}
/* Body Content for Notes */
.hq-body-content {
  font-size: 1rem;
  margin-bottom: 15px;
  padding: 10px;
  background: #34495e;
  border-radius: 8px;
  color: #f9fafb;
  line-height: 1.5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Placeholder Text */
.hq-notes-placeholder {
  font-size: 1rem;
  color: #bdc3c7;
}

/* Footer */
.hq-footer {
  background: #1c2833;
  color: #ecf0f1;
  text-align: center;
  padding: 10px 0;
  font-size: 0.9rem;
  border-top: 3px solid #2980b9;
}
.hq-sidebar,
.hq-navigator,
.hq-notes {
  border: 1px solid #445e78;
}