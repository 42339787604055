/* Notes Section */
.notes-section {
    margin-top: 30px;
    padding: 20px;
    background-color: #445e78;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.notes-title {
    font-size: 20px;
    font-weight: bold;
    color: white;
    margin-bottom: 15px;
}

.notes-content {
    font-size: 16px;
    color: white;
    line-height: 1.6;
}

/* Main Lists */
.notes-content ol,
.notes-content ul {
    margin: 10px 0; /* Add slight vertical spacing */
    padding-left: 20px; /* Indent main lists slightly */
    list-style-position: outside; /* Ensure bullets/numbers align correctly */
}

/* Nested Lists */
.notes-content ol li > ol,
.notes-content ul li > ul {
    margin-top: 5px; /* Add slight spacing for nested lists */
    padding-left: 20px; /* Increase indentation for nested lists */
    list-style-type: circle; /* Change bullet style for nested unordered lists */
    font-size: 15px; /* Slightly smaller font for nested lists */
}

/* Adjust Nested List Items */
.notes-content ol li > ol li,
.notes-content ul li > ul li {
    margin-bottom: 5px; /* Add consistent spacing between nested list items */
}

/* Bullet and Number Styling */
.notes-content ul {
    list-style-type: disc; /* Use discs for unordered lists */
}

.notes-content ul ul {
    list-style-type: circle; /* Use circles for nested unordered lists */
}

.notes-content ol {
    list-style-type: decimal; /* Ensure ordered lists display correctly */
}

.notes-content ol ol {
    list-style-type: lower-alpha; /* Use alphabetic markers for nested ordered lists */
}